import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Connect from 'components/Connect'
import EthPrice from 'components/EthPrice'
import Logo from 'components/Logo'
import loading from 'svg/loading.svg'
import Home from 'pages/Home'
import CryptoKittiesContract from 'contracts/cryptokitties'
import * as S from './App.style'

const { REACT_APP_END_POINT } = process.env

const AppView = ({ loggedIn, handleSignIn, handleSignOut, web3, user, token }) => {
  const [readMore, setReadMore] = useState(false)

  const handlePurchase = async (tokenId, value, sale) => {
		try {
			if (loggedIn) {
                console.log('hello')
				const instance = sale
                    ? new web3.eth.Contract(CryptoKittiesContract.Sale.abi, CryptoKittiesContract.Sale.addr)
                    : new web3.eth.Contract(CryptoKittiesContract.Sire.abi, CryptoKittiesContract.Sire.addr)
				const _sale = await instance.methods.bid(tokenId).send({ from: loggedIn, value })
				return true
			} else {
				handleSignIn()
				return false
			}
		} catch (e) {
			console.log(e)
			return false
		}
	}

  // const hennaCollection = [330173, 330845, 374334, 408110, 415315, 423589, 462120, 472252, 611965, 1998272, 2002112, 2002918]
  // const ancientKitties = [124,1097,1399,1498,3013,3023]
  // const collectibles = [130779, 198045, 310194, 340462, 350589, 407629, 419598, 527666, 575892,1317098,2002938]
  // const gilded = [3013,330637,330845,483199]
  // const ogSerpent = [372743,375753,450694,464805,465642]
  // const ogHotrod = [352545, 352872, 354720, 358985, 358998, 360831, 361076, 364375, 366226, 367201, 370518, 371091, 371527, 371719, 379912, 386224, 387863, 389437, 391245, 391473, 392002, 393611, 394775, 396772, 404623, 406766]
  // const ogFancy = [346643, 352407, 374882, 381897, 396156, 455874, 460719, 521005, 495596, 495777, 517857, 602181]
  // const hotrodWingtips = [462875, 470907, 465739, 470254, 471928, 472458, 492428, 505755, 524581, 677451, 713594, 1066363, 1075019, 1084980, 1154611, 1163975, 1164002, 1165531,1997772]
  // const glamourPuss = [364750, 399111, 464960, 466003, 467100, 472109, 483866, 483888, 496292, 524326, 524516, 543325, 584252, 628930, 629017, 629080, 632889, 632976, 639188, 640363, 640377, 774304,783527,819376,990795,991054,1077959,1078184,1139665,1161555]
  // const totesTips = [466696, 472576, 473767, 474537, 482385, 502598, 629032, 635459, 640118, 644786, 647368, 746360, 1054493, 1070178, 1070264, 1071905, 1072266, 1072376, 1073145, 1073563, 1074655, 1078813, 1078977, 1078979, 1081583, 1084807, 1138622, 1138784]

  // const collections = [
  //   ancientKitties, gilded, hennaCollection, collectibles,
  //   ogSerpent, ogHotrod, ogFancy, glamourPuss,
  //   hotrodWingtips, totesTips
  // ]

  // const collectionTitles = [
  //   'Day 1 Kitties', 'Gilded', 'Henna Collection', 'Collectibles',
  //   'Og Artwork', '2017 Hotrods', 'Year 1 Fancies', 'Wingtips',
  //   'Hotrod Wingtips', 'Totes Wingtips'
  // ]

  // const flatCollections = collections.flat()

  return (
    <S.Div>
      <EthPrice />
      <Logo />
      <S.Section>
        <p>Hello! I am the proud owner of @500 Cryptokitties, 3 Chained Horses and a 2 bit bear <span onClick={() => setReadMore(!readMore)}>read more..</span></p>
        {readMore && (
          <>
            <p>I started collecting Cryptokitties in 2017 and have been interested in blockchain / web3 dev ever since.</p>
            <p><a href={'https://kitty.family'} target={'_blank'}>kitty.family</a> and <a href={'https://kitty.news'} target={'_blank'}>kitty.news</a> were launched in 2018, and in 2021 I was hired by DapperLabs to work on the actual <a href={'https://cryptokitties.co'} target={'_blank'}>CryptoKitties website!</a></p>
            <p>In 2022 I fell in love with <a href={'https://twitter.com/moonfarm_eth'} target={'_blank'}>@moonfarmeth's</a> project ChainedHorses and made <a href={'https://paddock.chainedhorse.com'} target={'_blank'}>The Paddock</a> - a place where horse owners can meet and chat.</p>
            <p>But this is the site where it all started for me... Through 2023 Kitty.International will be evolving into a marketplace for OGs.</p>
            <p>But for now here is my collection - every kitty tells a story... </p>
          </>
        )}
      </S.Section>
      <Home {...{ handlePurchase }} />
      <Connect {...{ loggedIn, handleSignIn, handleSignOut }} />
    </S.Div>

  )
}

export default AppView
