import * as Styled from './Home.style'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Kitty from 'components/Kitty'

const { REACT_APP_END_POINT } = process.env

const _ids = [597907,451586,1077959,534729,130779, 198045, 310194, 350589, 407629, 419598, 527666, 575892,1317098, 372743, 464805, 387863, 391473, 1075019, 470129, 485548]

const Home = ({ handlePurchase }) => {
    const history = useNavigate()
    const [results, setResults] = useState(undefined)
    const [hats, setHats] = useState(undefined)
    const [needAFriend, setNeedAFrend] = useState(undefined)
    const [total, setTotal] = useState(undefined)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setResults(undefined);
                setHats(undefined);
                setTotal(undefined);
        
                const query = `${REACT_APP_END_POINT}/cryptokitties/nfts?include=sale,sire,other&search=id:${_ids.join(',')}`
                const { data: { kitties, total } } = await axios.get(query)
        
                const ids = kitties.map(({ tokenId }) => tokenId).join(',')
                const ckResult = await axios.get(`https://api.cryptokitties.co/v3/kitties?search=id:${ids}&limit=20`)
                const { data: { kitties: ckData } } = ckResult;
        
                const _kitties = kitties.map(kitty => ({
                  ...ckData.find(ck => ck.id === kitty.tokenId),
                  ...kitty
                }))
                setResults(_kitties.sort((a, b) => {
                    const indexA = _ids.indexOf(a.tokenId)
                    const indexB = _ids.indexOf(b.tokenId)
                    return indexA - indexB;
                }))
                setTotal(total)
                const query2 = `${REACT_APP_END_POINT}/cryptokitties/nfts?include=sire&search=wallet:0x51Ad709f827C6eC2Ed07269573abF592F83ED50c`
                const { data } = await axios.get(query2)
                const ids2 = data.kitties.map(({ tokenId }) => tokenId).join(',')
                const ckResult2 = await axios.get(`https://api.cryptokitties.co/v3/kitties?search=id:${ids2}&limit=20`)
                const { data: { kitties: ckData2 } } = ckResult2;
                const _kitties2 = data.kitties.map(kitty => ({
                  ...ckData2.find(ck => ck.id === kitty.tokenId),
                  ...kitty
                }))
                setNeedAFrend(_kitties2)
            } catch (error) {
              console.error(error)
            }
          };
        
          fetchData()
    }, [])

    return (
        <Styled.Div>
            <h2>Kitties with Kitty Hats</h2>
            <Styled.Grid>
                {results && results.map((kitty, i) => {
                    return i <= 4 && (
                        <Styled.Container key={i}>
                            <Kitty {...{ kitty, handlePurchase }} showMewts={true} showInfo={false} hats={[]} getInfo={tokenId => window.location.href = `https://kitty.family/kitty/${tokenId}`} />
                            {/* {hats.filter(({ tokenId }) => tokenId === kitty.tokenId).map((hat, i) => {
                                return (
                                    <div key={i}>
                                        {i === 0 && <h3><Link to={`/kitty/${kitty.tokenId}`}>#{kitty.tokenId}{kitty.name && ` - ${kitty.name}`}</Link></h3>}
                                        <h3>🎩  {allHats.find(({ contract }) => contract.replace('Item', '') === hat.itemName).itemName}</h3>
                                        <h4>{hat.issue + 1} of ({allHats.find(({ contract }) => contract.replace('Item', '') === hat.itemName).amount})</h4>
                                        <p>{formatDate(hat.appliedTimestamp)}</p>
                                    </div>
                                )
                            })} */}
                        </Styled.Container>
                        
                    )
                })}
            </Styled.Grid>

            {/* <h2>Need a friend?</h2>
            <Styled.Grid>
                {needAFriend && needAFriend.map((kitty, i) => {
                    return (
                        <Styled.Container key={i}>
                            <Kitty {...{ kitty, handlePurchase }} showInfo={false} showMewts={true} showName={true} hats={[]} getInfo={tokenId => window.location.href = `https://kitty.family/kitty/${tokenId}`} />
                        </Styled.Container>
                        
                    )
                })}
            </Styled.Grid> */}


            <h2>OG Hotrods</h2>
            <Styled.Grid>
                {results && results.map((kitty, i) => {
                    return i >= 15 && (
                        <Styled.Container key={i}>
                            <Kitty {...{ kitty, handlePurchase }} showInfo={false} showMewts={true} showName={true} hats={[]} getInfo={tokenId => window.location.href = `https://kitty.family/kitty/${tokenId}`} />
                            {/* {hats.filter(({ tokenId }) => tokenId === kitty.tokenId).map((hat, i) => {
                                return (
                                    <div key={i}>
                                        {i === 0 && <h3><Link to={`/kitty/${kitty.tokenId}`}>#{kitty.tokenId}{kitty.name && ` - ${kitty.name}`}</Link></h3>}
                                        <h3>🎩  {allHats.find(({ contract }) => contract.replace('Item', '') === hat.itemName).itemName}</h3>
                                        <h4>{hat.issue + 1} of ({allHats.find(({ contract }) => contract.replace('Item', '') === hat.itemName).amount})</h4>
                                        <p>{formatDate(hat.appliedTimestamp)}</p>
                                    </div>
                                )
                            })} */}
                        </Styled.Container>
                        
                    )
                })}
            </Styled.Grid>

            <h2>Private Collection...</h2>
            <Styled.Grid>
                {results && results.map((kitty, i) => {
                    return i >= 5 && i < 15 && (
                        <Styled.Container key={i}>
                            <Kitty {...{ kitty, handlePurchase }} showInfo={false} showMewts={true} showName={true} hats={[]} getInfo={tokenId => window.location.href = `https://kitty.family/kitty/${tokenId}`} />
                            {/* {hats.filter(({ tokenId }) => tokenId === kitty.tokenId).map((hat, i) => {
                                return (
                                    <div key={i}>
                                        {i === 0 && <h3><Link to={`/kitty/${kitty.tokenId}`}>#{kitty.tokenId}{kitty.name && ` - ${kitty.name}`}</Link></h3>}
                                        <h3>🎩  {allHats.find(({ contract }) => contract.replace('Item', '') === hat.itemName).itemName}</h3>
                                        <h4>{hat.issue + 1} of ({allHats.find(({ contract }) => contract.replace('Item', '') === hat.itemName).amount})</h4>
                                        <p>{formatDate(hat.appliedTimestamp)}</p>
                                    </div>
                                )
                            })} */}
                        </Styled.Container>
                    )
                })}
            </Styled.Grid>
            <img src={'https://img.cryptokitties.co/0x06012c8cf97bead5deae237070f9587f8e7a266d/124.png'} alt={'CryptoKitty #124 - Furlin'} />
            <p>For all enquiries please contact: <a href="mailto:kitties@kitty.international">kitties@kitty.international</a></p>
        </Styled.Div>
    )
}

export default Home