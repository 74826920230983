import * as Styled from './PriceC2A.style'
import { fromWei } from 'web3-utils'
import { ReactComponent as Loading } from 'svg/loading.svg'

const unPad = (str) => str.replace(/^0+/, '')

const PriceC2A = ({ price, disabled, loading, handleClick, sale }) => {
	return (
		<Styled.Span {...{ disabled }} onClick={() => !disabled && handleClick(unPad(price))}>
			<img src={sale ? '/images/icons/buy.svg' : '/images/icons/eggplant.svg'} />
			{loading ? (
				<Loading />
			) : (
				<>
					<span>Ξ</span>
					{price && fromWei(unPad(price), 'ether')}
				</>
			)}
		</Styled.Span>
	)
}

export default PriceC2A
